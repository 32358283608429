
import Vue from 'vue';

export default Vue.extend({
  components: {
    AppGlobals: () => import('../global/globals.vue'),
    companyLogo: () => import('../global/companyLogo.vue'),
    MainFooter: () => import('../global/footer.vue'),
  },
  data: () => ({
    snackbar: false,
    loading: false,
    checksFile: null,
    errorMessage: null,
    dialog: false,
    checks: [],
    checkResult: null,
  }),
  mounted(): void {
    if (!this.$route.params.file) {
      this.errorMessage = 'not a valid health check page';
      this.snackbar = true;
      return;
    }
    this.checksFile = this.$route.params.file;
    this.loading = true;
    this.$store
      .dispatch('admin/getHealthChecks', { file: this.checksFile })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((list: any[]) => {
        if (!list) {
          this.errorMessage = 'failed to get health checks list';
          this.snackbar = true;
          // eslint-disable-next-line no-console
          console.error('getHealthChecks error: no list', list);
          return;
        }

        // console.log('getHealthChecks', list);
        this.checks = list;
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.error('getHealthChecks error', error);
        this.errorMessage = error.message || 'failed to get health checks list';
        this.snackbar = true;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    buildInfo(): string {
      if (process && process.env && process.env.VUE_APP_BUILD_VERSION && process.env.VUE_APP_BUILD_VERSION.length > 8) {
        return `v.1.${process.env.VUE_APP_BUILD_VERSION.substr(3, 6)}`;
      }
      return 'n/a';
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    runCheck(check: any): void {
      check.loading = true;
      const index = this.checks.indexOf(check);
      if (index === -1) {
        return;
      }
      Vue.set(this.checks, index, check);
      this.$store
        .dispatch('admin/runHealthCheck', { file: this.checksFile, key: check.key })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((result: any) => {
          if (!result) {
            this.errorMessage = 'failed to run health check';
            this.snackbar = true;
            // eslint-disable-next-line no-console
            console.error('runHealthCheck error: no result', result);
            return;
          }

          // console.log('runHealthCheck', result);
          this.checkResult = result;
          this.dialog = true;
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          // eslint-disable-next-line no-console
          console.error('runHealthCheck error', error);
          this.errorMessage = error.message || 'failed to run health check';
          this.snackbar = true;
        })
        .finally(() => {
          check.loading = false;
          Vue.set(this.checks, index, check);
        });
    },
  },
});
